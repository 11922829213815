import axios from 'axios';
let api_URL = 'https://apixgen.javisauto.live:8801'

const instance = axios.create({
  baseURL: api_URL
});

const AUTH_TOKEN = "123"
instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;

export default instance;
