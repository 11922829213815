import React, { useState, useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import Axios from 'axios';
import instance from "./axios-instance";

function App() {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        let filename = await handleSave(file);
        console.log(15, filename);
        const binaryStr = reader.result
        let pic = {
          file: binaryStr,
          no: binaryStr.length + 1,
          link: filename
        }
        imageFilelist.push(pic)
        console.log(pic);
        imageFilelist.sort().reverse();
        setImageFilelist([...imageFilelist]);
      }
    })

  }, [])
  const [imageFilelist, setImageFilelist] = React.useState([]);

  useEffect(() => {
    // action on update of movies
  }, [imageFilelist]);

  Axios.defaults.withCredentials = true;
  const [imageFile, setImageFile] = useState('');

  const handleSave = async (files) => {
    const formData = new FormData();
    formData.append('System', "javisauto");
    formData.append('image', files);
    let headerss = {
      'Content-Type': 'multipart/form-data',
      'Authorization': 'Bearer langter',
    };
    return await instance.post('/api/v1/newimage', formData, { headers: headerss })
      .then((res) => {
        console.log(48, res.data);
        return res.data.link
      }).catch((error) => {
        console.log(4855, error);
      });
  }
  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const handleSubmitFile = (e) => {
    e.preventDefault();
    if (!imageFile) return;
    Axios.put(`api url`, { data: imageFile });
  }
  return (
    <div className="upload" >
      <div
        {...getRootProps()}
        className={`dropzone active`}
      >
        <input {...getInputProps()} />
        {
          <p>ลากวาง...</p>
        }
      </div>
      <form onSubmit={handleSubmitFile}>
        <button type="submit">
          submit
        </button>
      </form >
      <div className="markdown-body css-1duitbk">
        <h1>รายการรูปภาพ</h1>
        <p className="description ad">
          ลิสรายการ.
        </p>
        <div id="carbonads">
          {imageFilelist.map((image) => (
            <>
              <div class="row" style={{ width: "30%" }}>
                <div class="column">
                  <span className="carbon-wrap">
                    <img
                      src={image.file}
                      alt="ads via Carbon"
                      border={0}
                      height={100}
                    />
                  </span>
                </div>
              </div>
              <div class="row" style={{ width: "30%" }}>
                <span  >
                  {image.link}
                </span>
                <br></br>
                <br></br>
              </div>
            </>
          ))}

        </div>

      </div>

    </div >
    // <div>
    //   <div {...getRootProps()}>
    //     <input {...getInputProps()} />
    //     {
    //       <p>Drop the files here ...</p>
    //     }
    //   </div>

    //   <form onSubmit={handleSubmitFile}>
    //     <button type="submit">
    //       submit
    //     </button>
    //   </form >
    // </div >


    // <div {...getRootProps()}>
    //   <input {...getInputProps()} />
    //   <p>Drag 'n' drop some files here, or click to select files</p>
    // </div>
  )
}
export default App;